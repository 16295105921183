import React, { useState } from 'react';
import * as styles from '../styles/burger.scss';


const Burger = ({ burgerClass }) => {

    const [menuOpen, setMenuOpen] = useState(false);
    const [opened, setOpened] = useState(false);
    const menuOptions = {
        'home': '/',
        'case studies': '/blog',
        'blog': '/blog',
        'contact': '/#contact'
    }

    const toggleMenu = () => {
        setMenuOpen(!menuOpen)
        setOpened(true);
        sendToGTM('menu_toggle')
    }

    const sendToGTM = (event, eventAction = null) => window.dataLayer?.push({ event, eventAction })
    
    return (
        <>
            <div onClick={toggleMenu} className={`icon icon-menu`}>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" height="40" viewBox="0 0 40 40" width="40"><g clip-rule="evenodd" fill="#000" fill-rule="evenodd"><path d="m3.75 10c0-.69036.55964-1.25 1.25-1.25h30c.6904 0 1.25.55964 1.25 1.25 0 .6904-.5596 1.25-1.25 1.25h-30c-.69036 0-1.25-.5596-1.25-1.25z"/><path d="m3.75 30c0-.6904.55964-1.25 1.25-1.25h30c.6904 0 1.25.5596 1.25 1.25s-.5596 1.25-1.25 1.25h-30c-.69036 0-1.25-.5596-1.25-1.25z"/><path d="m10.4167 20c0-.6904.5596-1.25 1.25-1.25h23.3333c.6904 0 1.25.5596 1.25 1.25s-.5596 1.25-1.25 1.25h-23.3333c-.6904 0-1.25-.5596-1.25-1.25z"/></g></svg>
            </div>
            <section className={`side-menu ${menuOpen ? 'open' : 'closed'}`}>
                <div className="icon icon-close" onClick={toggleMenu}>
                    <svg id="fi_2961937" height="512" viewBox="0 0 64 64" width="512" xmlns="http://www.w3.org/2000/svg"><path d="m4.59 59.41a2 2 0 0 0 2.83 0l24.58-24.58 24.59 24.58a2 2 0 0 0 2.83-2.83l-24.59-24.58 24.58-24.59a2 2 0 0 0 -2.83-2.83l-24.58 24.59-24.59-24.58a2 2 0 0 0 -2.82 2.82l24.58 24.59-24.58 24.59a2 2 0 0 0 0 2.82z"></path></svg>
                </div>
                <ul>
                    {Object.keys(menuOptions).map(option => {
                        return (
                            <li className="ff" key={option} onClick={() => {setMenuOpen(false); sendToGTM('menu_navigation', option)}}><a href={menuOptions[option]}>{option}</a></li>
                        )
                    })}
                </ul>
            </section>
        </>

    )

}

export default Burger